import { List } from "immutable";
import moment from "moment";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiLanguages,
  AppHeading1,
  AppLanguageSelector,
  AppSubHeadingStyled,
  ArrowHideContentIcon,
  ArrowShowContentIcon,
  BackArrowIcon,
  Booking,
  Service,
  Settings,
  StepNames,
  STEP_NAMES,
  Theme,
} from "../../../shared";
import { AppCurrencySelector } from "../../../shared/components/appCurrencySelector";
import colors from "../../../styles/theme/colors";
import {
  getColorContrast,
  styled,
  withTheme,
} from "../../../styles/theme/themeHelper";
import {
  getFirstPageFromRouter,
  getParamFromRouter,
} from "../../../utils/getFirstPageFromRouter";
import { isProductsBeforeStoreStep } from "../../../utils/pageRouter";
import {
  StepIndicator,
  StepIndicatorProps,
  StepIndicatorState,
} from "./stepIndicator";
import { getIsUserInVideoMeeting } from "../../../components/bookingVideoMeeting/selectors";
import { useSelector } from "react-redux";
import { getIsLiveChat } from "../../../components/summary/selectors";
export interface BreadcrumbsProps {
  selectedProductHasAddons?: boolean;
  currentStepName: StepNames | null;
  products: List<Service>;
  steps: StepIndicatorProps[];
  theme: Theme;
  availableLanguages?: ApiLanguages | void;
  currentLanguage?: string;
  onLanguageSelection?: (language: string) => void;
  showLanguageSelector?: boolean;
  showCurrencySelector?: boolean;
  showGoBack?: boolean;
  showProductSelectionIfSingleProduct?: boolean;
  showQueueSelection?: boolean;
  onGoBack?: () => void;
  bookingStatus?: string;
  booking?: Booking;
  singleProduct?: Service;
  settings?: Settings;
  firstPageProp?: StepNames;
}

type Props = BreadcrumbsProps;

const StyledBreadcrumbs = styled.div<{ column?: boolean }>`
  opacity: ${(props) => (props.theme.logo ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  background-color: ${(props) => props.theme.body.backgroundColor};
  border-bottom: solid 1px
    ${(props) => getColorContrast(props.theme.body.backgroundColor)};
  min-height: ${(props) => (props.theme.isMobile ? 40 : 70)}px;
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  align-items: center;

  .container {
    min-height: 70px;
    padding: 0px 0px;
  }

  .steps {
    margin: 0px !important;
    padding: ${(props) => (props.theme.isMobile ? 0 : 15)}px 0 0 0 !important;
  }
`;

const StyledCorners = styled.div`
  width: 60px;
  margin: auto 0px;
  height: 100%;
`;

const BackArrowWrapper = styled.div`
  cursor: pointer;
  height: 18px;

  [role="button"] {
    border: 2px solid transparent;
    height: 22px;
    width: 22px;
  }
`;

const CompletedBookingText = styled.div`
  align-self: center;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.body.font.mainTextColor || colors.text1};
`;

const StyledNav = styled.nav`
  display: flex;
  ${(props) => (props.theme.isMobile ? "width: 100%;" : "")}

  ol {
    ${(props) =>
      props.theme.isMobile
        ? " display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center;"
        : ""}
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;
  justify-content: center;
`;

const ArrowIconWrapper = styled.div`
  padding-top: 10px;
  flex: 1;
  text-align: center;
  width: 100%;
`;

const BreadcrumbsBase: React.FunctionComponent<Props> = ({
  currentStepName,
  products,
  selectedProductHasAddons,
  steps,
  availableLanguages,
  currentLanguage,
  onLanguageSelection,
  showLanguageSelector,
  showCurrencySelector,
  showGoBack,
  showProductSelectionIfSingleProduct,
  showQueueSelection,
  theme,
  onGoBack,
  bookingStatus,
  booking,
  singleProduct,
  settings,
  firstPageProp,
}: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const path = router?.pathname.split("/");
  const storeFinderHeader = t("storeFinderHeader", {
    defaultValue: "Find your local store and book an appointment",
  });
  const cancellationHeader = t("cancellationHeader", {
    defaultValue: "",
  });
  const errorHeader = t("error", { defaultValue: "Error" });
  const profileHeader = t("staffProfileHeader", {
    defaultValue: "Profile page",
  });

  const [title, setTitle] = React.useState<string | undefined>(undefined);
  const [openBreadcrumb, setOpenBreadcrumb] = React.useState<boolean>(false);

  const hideBreadcrumb = getParamFromRouter() === "weblink";
  const isUserInVideoMeeting = useSelector(getIsUserInVideoMeeting);
  const isLiveJourney = useSelector(getIsLiveChat);

  useEffect(() => {
    if (!(currentStepName === "booking" && singleProduct?.virtual)) {
      setTitle(undefined);
    }

    if (
      currentStepName === "booking" &&
      singleProduct?.virtual &&
      booking &&
      !isUserInVideoMeeting
    ) {
      const secondsUntilMeeting = isLiveJourney
        ? 1
        : moment().diff(booking.startDateTime, "seconds");
      if (
        secondsUntilMeeting > -1800 &&
        secondsUntilMeeting <= 0 &&
        !(
          booking.bookingMeetingUrl || booking.bookingMeetingQudiniVideoDetails
        ) &&
        booking.status !== "SERVED"
      ) {
        setTitle(
          t("breadcrumbsVirtualAboutToStart", {
            defaultValue: "Your appointment is about to start",
          })
        );
      } else if (
        secondsUntilMeeting > 0 &&
        !(
          booking.bookingMeetingUrl || booking.bookingMeetingQudiniVideoDetails
        ) &&
        booking.status === "SERVED"
      ) {
        setTitle(
          t("breadcrumbsVirtualTimeOver", {
            defaultValue: "Waiting for your advisor to join the call",
          })
        );
      } else if (
        (booking.bookingMeetingUrl ||
          booking.bookingMeetingQudiniVideoDetails) &&
        booking.status === "SERVED"
      ) {
        setTitle(
          t("breadcrumbsVirtualMeetingReady", {
            defaultValue: "Your advisor is on the call ready to start",
          })
        );
      }
    }

    if (isUserInVideoMeeting) {
      setTitle(undefined);
    }

    if (currentStepName === STEP_NAMES.CANCELLATION) {
      setTitle(cancellationHeader);
    }

    if (currentStepName === STEP_NAMES.ERROR) {
      setTitle(errorHeader);
    }

    if (currentStepName === STEP_NAMES.PROFILE) {
      setTitle(profileHeader);
    }
  }, [
    currentStepName,
    currentLanguage,
    singleProduct,
    booking,
    isUserInVideoMeeting,
  ]);

  const firstPage =
    firstPageProp ??
    getFirstPageFromRouter(settings, !!singleProduct?.numberOfAddOns);
  const isVenuesDeeplink =
    router?.query.from?.includes("/venues") ||
    router?.query.from?.includes("/type/venues");

  const isProductsBeforeStore = isProductsBeforeStoreStep(router, firstPage);

  const renderSteps = () => {
    let currentSelectedIndex: number;

    const stepAliases: Partial<Record<StepNames, StepNames>> = {
      [STEP_NAMES.RESCHEDULE]: STEP_NAMES.SLOTS,
      [STEP_NAMES.LIVE_CHAT_PRODUCTS]: STEP_NAMES.PRODUCTS,
    };

    const currentStep =
      currentStepName && (stepAliases[currentStepName] ?? currentStepName);

    return steps.map((step, index) => {
      const addOnsPage =
        currentStep === STEP_NAMES.ADDONS &&
        step.stepName === STEP_NAMES.PRODUCTS;
      const queuesPage =
        currentStep === STEP_NAMES.QUEUES &&
        step.stepName === STEP_NAMES.PRODUCTS;

      if (currentStep === step.stepName || addOnsPage || queuesPage) {
        step.stepState = 1;
        currentSelectedIndex = index + 1;
      }

      if (!currentSelectedIndex) {
        step.stepState = 2;
      }

      if (currentStep === STEP_NAMES.TYPE) {
        return;
      }

      if (
        !isProductsBeforeStore &&
        currentStep === step.stepName &&
        step.stepTitle &&
        firstPage !== STEP_NAMES.TYPE
      ) {
        setTitle(step.stepTitle);
        return;
      }

      if (!queuesPage) {
        if (
          (step.stepName === STEP_NAMES.VENUES &&
            firstPage !== STEP_NAMES.VENUES &&
            !(
              router?.query.from === "/products" ||
              router?.query.from === "/type/products" ||
              index === 1 ||
              (firstPage === STEP_NAMES.TYPE && index === 2) ||
              currentStep === STEP_NAMES.DETAILS ||
              currentStep === STEP_NAMES.BOOKING
            )) ||
          (step.stepName === STEP_NAMES.VENUES && isVenuesDeeplink) ||
          (step.stepName === STEP_NAMES.PRODUCTS &&
            !showQueueSelection &&
            !selectedProductHasAddons &&
            products.size <= 1 &&
            !showProductSelectionIfSingleProduct) ||
          (step.stepName === STEP_NAMES.PRODUCTS &&
            firstPage === STEP_NAMES.SLOTS &&
            !selectedProductHasAddons)
        ) {
          return;
        }
      }

      // handle reschedule state

      const stepUpdated = {
        ...step,
        stepState:
          step.stepName === STEP_NAMES.BOOKING &&
          step.stepState === StepIndicatorState.active
            ? StepIndicatorState.checked
            : step.stepState,
      };

      return (
        <StepIndicator
          key={index}
          {...stepUpdated}
          stepCompleteTranslation={t("complete", { defaultValue: "Complete" })}
          stepCurrentTranslation={t("current", { defaultValue: "current" })}
        />
      );
    });
  };

  const handleGoBackArrow = () => {
    if (onGoBack) {
      onGoBack();
    }
  };
  const storeFinderSubHeader = t("storeFinderSubHeader", { defaultValue: "" });

  const renderTitle = () => {
    return (
      <StyledDiv>
        <AppHeading1 id={`breadcrumb-${currentStepName}-title`}>
          {currentStepName === STEP_NAMES.VENUES ? storeFinderHeader : title}
        </AppHeading1>
        {currentStepName === STEP_NAMES.VENUES && storeFinderSubHeader && (
          <AppSubHeadingStyled
            id={`breadcrumb-${currentStepName}-subtitle`}
            style={{ textAlign: "center" }}
          >
            {storeFinderSubHeader}
          </AppSubHeadingStyled>
        )}
      </StyledDiv>
    );
  };

  const availableLanguagesStrings =
    availableLanguages &&
    availableLanguages.languages.map(({ isoCode }) => isoCode);

  const hideLanguageSelector =
    !(
      availableLanguages ||
      currentLanguage ||
      onLanguageSelection ||
      showLanguageSelector
    ) ||
    (isLiveJourney &&
      path[path.length - 1] !== STEP_NAMES.TYPE &&
      path[path.length - 1] !== STEP_NAMES.LIVE_CHAT_PRODUCTS);

  const hasTitle = title !== undefined;

  if (
    bookingStatus === "POST_SERVED" &&
    !theme.isMobile &&
    currentStepName === STEP_NAMES.BOOKING
  ) {
    return (
      <StyledBreadcrumbs id="breadcrumbs">
        <div className="d-flex justify-content-between container">
          <StyledCorners />
          <CompletedBookingText theme={theme}>
            {t("breadcrumbsPostServed", {
              defaultValue: "Thank you for visiting",
            })}
          </CompletedBookingText>
          <StyledCorners>
            <AppLanguageSelector
              availableLanguages={availableLanguagesStrings}
              currentLanguage={currentLanguage}
              onLanguageSelection={onLanguageSelection}
              backgroundColor={theme.body.backgroundColor}
              fontColor={theme.body.font.mainTextColor}
              outline
              hide={false}
            />
          </StyledCorners>
        </div>
      </StyledBreadcrumbs>
    );
  }

  const classNameHelper = () => {
    if (hasTitle) {
      return "";
    }
    return "steps";
  };

  const showBreadcrumb = theme.isMobile
    ? currentStepName !== STEP_NAMES.VENUES || isProductsBeforeStore
    : true;

  const handleOpenBreadcrumb = () => {
    setOpenBreadcrumb(!openBreadcrumb);
  };
  return !hideBreadcrumb && currentStepName && showBreadcrumb ? (
    <StyledBreadcrumbs id="breadcrumbs" column={openBreadcrumb}>
      {theme.isMobile && (
        <ArrowIconWrapper
          tabIndex={0}
          role="button"
          onKeyPress={handleOpenBreadcrumb}
          aria-label={t(
            openBreadcrumb ? "ariaLabelViewSteps" : "ariaLabelCloseSteps",
            {
              defaultValue: openBreadcrumb ? "View steps" : "Close steps",
            }
          )}
          onClick={handleOpenBreadcrumb}
        >
          {openBreadcrumb ? (
            <ArrowShowContentIcon
              ariaHiddenValue={true}
              color={theme.body.font.mainTextColor}
            />
          ) : (
            <ArrowHideContentIcon
              ariaHiddenValue={true}
              color={theme.body.font.mainTextColor}
            />
          )}
        </ArrowIconWrapper>
      )}
      {(!theme.isMobile || openBreadcrumb) && (
        <div className="d-flex justify-content-between container">
          {!theme.isMobile && (
            <StyledCorners>
              {showGoBack && (
                <BackArrowWrapper
                  tabIndex={0}
                  role="button"
                  onKeyPress={handleGoBackArrow}
                  aria-label={t("ariaLabelGoBackButton", {
                    defaultValue: "Go to the previous page",
                  })}
                  onClick={handleGoBackArrow}
                >
                  <BackArrowIcon
                    ariaHiddenValue={true}
                    color={theme.body.font.mainTextColor}
                  />
                </BackArrowWrapper>
              )}
            </StyledCorners>
          )}
          {hasTitle ? (
            renderTitle()
          ) : (
            <StyledNav>
              <ol
                aria-label={t("ariaLabelBreadcrumbs", {
                  defaultValue: "Steps",
                })}
                className={`p-2 m-auto ${classNameHelper()}`}
              >
                {renderSteps()}
              </ol>
            </StyledNav>
          )}
          {!theme.isMobile && (
            <>
              {showCurrencySelector && (
                <StyledCorners>
                  <AppCurrencySelector
                    fontColor={theme.body.font.mainTextColor}
                    backgroundColor={theme.body.backgroundColor}
                    outline
                  />
                </StyledCorners>
              )}

              <StyledCorners>
                <AppLanguageSelector
                  availableLanguages={availableLanguagesStrings}
                  currentLanguage={currentLanguage}
                  onLanguageSelection={onLanguageSelection}
                  backgroundColor={theme.body.backgroundColor}
                  fontColor={theme.body.font.mainTextColor}
                  outline
                  hide={hideLanguageSelector}
                />
              </StyledCorners>
            </>
          )}
        </div>
      )}
    </StyledBreadcrumbs>
  ) : (
    <></>
  );
};

export const Breadcrumbs = withTheme(BreadcrumbsBase);
