import { useTranslation } from "react-i18next";
import { Questions, Settings, StepNames, STEP_NAMES } from "../shared";
import { getFirstPageFromRouter } from "./getFirstPageFromRouter";
import { getIsAllQuestionsAnsweredViaQueryParams } from "./getQuestionsFromQueryParams";
import { StepIndicatorState } from "../modules/breadcrumbs/components/stepIndicator";

interface Breadcrumbs {
  stepLabel: string;
  stepName: StepNames;
  stepState: number;
  stepTitle?: string;
}

const removeStepsHelper = (
  breadcrumbs: Breadcrumbs[],
  name: StepNames
): Breadcrumbs[] => {
  const stepIndex = breadcrumbs.findIndex(({ stepName }) => name === stepName);
  breadcrumbs.splice(stepIndex, 1);
  return breadcrumbs;
};

export const useGenerateSteps = (
  settings?: Settings,
  questions?: Questions[],
  firstPage?: StepNames,
  from?: string | string[],
  pathname?: string,
  isLiveChat?: boolean,
  showPaymentStep?: boolean
) => {
  const { t } = useTranslation();
  if (!settings) {
    return [];
  }
  if (!firstPage) {
    firstPage = getFirstPageFromRouter();
  }

  const venuesStep = {
    stepLabel: t("location", { defaultValue: "Location" }),
    stepName: STEP_NAMES.VENUES,
    stepState: 0,
    stepTitle: t("storeFinderHeader", {
      defaultValue: "Find your local store and book an appointment",
    }),
  };

  const typeStep = {
    stepLabel: t("type", { defaultValue: "Type" }),
    stepName: STEP_NAMES.TYPE,
    stepState: StepIndicatorState.blank,
  };

  const productsStep = {
    stepLabel: t("service", { defaultValue: "Service" }),
    stepName: STEP_NAMES.PRODUCTS,
    stepState: StepIndicatorState.blank,
  };

  const showQuestionStep =
    settings.showCustomerQuestions &&
    questions?.length &&
    !getIsAllQuestionsAnsweredViaQueryParams(questions) &&
    !settings.combineCustomerDetailsAndYourVisitQuestions;

  const questionsStep = showQuestionStep
    ? [
        {
          stepLabel: t("questions", { defaultValue: "Your visit" }),
          stepName: STEP_NAMES.CONFIRMATION,
          stepState: StepIndicatorState.blank,
        },
      ]
    : [];

  const advisorsStep = {
    stepLabel: t("advisor", { defaultValue: "Advisor" }),
    stepName: STEP_NAMES.ADVISORS,
    stepState: StepIndicatorState.blank,
  };

  const notVirtualOrLiveJourneyPath =
    !pathname?.includes?.("virtual") &&
    !from?.includes?.("/virtual/products") &&
    !pathname?.includes?.("live") &&
    !from?.includes?.("/live");

  const showAdvisorsStep =
    settings.staffOptions.showStaffSelection && notVirtualOrLiveJourneyPath;
  const showStaffSelectionAfterCalendar =
    settings.staffOptions.showStaffSelectionAfterCalendar;

  const advisorsStepBeforeCalendar =
    showAdvisorsStep && !showStaffSelectionAfterCalendar ? [advisorsStep] : [];
  const advisorsStepAfterCalendar =
    showAdvisorsStep && showStaffSelectionAfterCalendar ? [advisorsStep] : [];

  const paymentStep = showPaymentStep
    ? [
        {
          stepLabel: t("payment", { defaultValue: "Payment" }),
          stepName: STEP_NAMES.PAYMENT,
          stepState: StepIndicatorState.blank,
        },
      ]
    : [];

  const breadcrumbs: Breadcrumbs[] = [
    venuesStep,
    productsStep,
    ...advisorsStepBeforeCalendar,
    {
      stepLabel: t("dateTime", { defaultValue: "Date & Time" }),
      stepName: STEP_NAMES.SLOTS,
      stepState: StepIndicatorState.blank,
    },
    ...advisorsStepAfterCalendar,
    {
      stepLabel: t("details", { defaultValue: "Details" }),
      stepName: STEP_NAMES.DETAILS,
      stepState: StepIndicatorState.blank,
    },
    ...questionsStep,
    ...paymentStep,
    {
      stepLabel: t("confirmation", { defaultValue: "Confirmation" }),
      stepName: STEP_NAMES.BOOKING,
      stepState: StepIndicatorState.blank,
    },
  ];

  switch (firstPage) {
    case STEP_NAMES.PRODUCTS_BEFORE_STORE: {
      breadcrumbs.shift();
      breadcrumbs.splice(1, 0, venuesStep);
      return breadcrumbs;
    }
    case STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE: {
      breadcrumbs.splice(0, 2);
      breadcrumbs.unshift(productsStep);
      return breadcrumbs;
    }
    case STEP_NAMES.LIVE_CHAT_PRODUCTS: {
      breadcrumbs.shift();
      breadcrumbs.splice(1, 1);
      return breadcrumbs;
    }
    case STEP_NAMES.TYPE: {
      const isVirtualProductsBeforeStore =
        window.location.pathname.split("/")[3] === "virtual" &&
        window.location.pathname.split("/")[4] === "products";

      const isProductsBeforeStore =
        window.location.pathname.split("/")[3] === "products";

      if (pathname?.includes("live") || from?.includes("live") || isLiveChat) {
        removeStepsHelper(breadcrumbs, STEP_NAMES.VENUES);
        removeStepsHelper(breadcrumbs, STEP_NAMES.SLOTS);
      }

      if (isVirtualProductsBeforeStore || from?.includes("/virtual/products")) {
        breadcrumbs.splice(0, 2);
        breadcrumbs.unshift(productsStep);
      }

      if (
        isProductsBeforeStore ||
        (from?.includes("products") && !from?.includes("virtual"))
      ) {
        breadcrumbs.shift();
        breadcrumbs.splice(1, 0, venuesStep);
      }

      breadcrumbs.unshift(typeStep);

      return breadcrumbs;
    }
    default: {
      if (isLiveChat) {
        removeStepsHelper(breadcrumbs, STEP_NAMES.VENUES);
        removeStepsHelper(breadcrumbs, STEP_NAMES.SLOTS);
      }
      return breadcrumbs;
    }
  }
};
